// import system
import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
// import page
import demo from './demo';
import chu_montpellier from './CHU/chu_montpellier'
import pages from './PAGES/pages';


class App extends React.Component {
  render(){
    return (
      <div className="App">
        <Router>
        <Route path="/" exact component={demo}/>
        <Route path="/2bfcd2a302b4762b9a4bbb34591aa3f3" exact component={chu_montpellier}/>
        <Route path="/b3b32a2d422265cd25c3323ed0157f81" exact component={pages}/>
        </Router>
      </div>
    );
  }
}

export default App;