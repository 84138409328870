import React from 'react';
import '../App.css';
import MappingPages from './mapping_pages'

class pages extends React.Component{
    render(){
        return(
            <div className="App">
                <MappingPages />
            </div>
        );
    }
}

export default pages;