/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './App.css';
import MappingDemo from './mapping_demo';

class demo extends React.Component{
    render(){    
        return(
            <div className="App">
                <MappingDemo />
            </div>
        )
    }
}

export default demo;