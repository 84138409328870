import React from 'react';
import './App.css';
import chu from './data/test.json'

class MappingDemo extends React.Component {  
  
  next(){
    window.scrollBy(0, 1110);
  }

  prec(){
    window.scrollBy(0, -1110);
  }

  render(){
    return(
      <div>
        {chu.map((entry, i) => {
          return(
            <div key={i}>
              <div className="background">
                <div className="container">
                  <div className="container_client">
                    <h1 className="title_client center">Entrée client</h1>
                    <p className="interligne sizing_entree">Code résultat : <span className="characters">{entry['Entrée complète client']['CODE RESULTAT']}</span></p>
                    <p className="interligne sizing_entree">Lib : <span className="characters">{entry['Entrée complète client'].LIB}</span></p>
                    <p className="interligne sizing_entree">Lib long : <span className="characters">{entry['Entrée complète client']['LIB Long']}</span></p>
                    <p className="interligne sizing_entree">Est numérique : <span className="characters">{entry['Entrée complète client']['est numérique']}</span></p>
                    <p className="interligne sizing_entree">Unité : <span className="characters">{entry['Entrée complète client'].unité}</span></p>
                    <p className="interligne sizing_entree">Libelle : <span className="characters">{entry['Entrée complète client'].Libelle}</span></p>
                    <p className="interligne sizing_entree">Nature prel : <span className="characters">{entry['Entrée complète client']['nature prel']}</span></p>
                    <div className="container_compteur">{i+1}/10</div>
                  </div>
                  <div>
                    <h1 className="title_propositions margin_title">Propositions</h1>
                    <div className="container_propositions">{entry['Candidats LOINC'].map((candidats, index) => {
                      return(
                        <div key={index} className="container_propositions_cards">
                          <p className="characters">{index}</p>
                          <p className="interligne center"><span className="characters">{candidats.Composant}</span></p>
                          <p className="interligne center">{candidats['Code LOINC']}</p>
                          <hr></hr>
                          <p className="interligne alignement">Grandeur : <span className="characters">{candidats.Grandeur}</span></p>
                          <p className="interligne alignement">Milieu : <span className="characters">{candidats.Milieu}</span></p>
                          <p className="interligne alignement">Échelle : <span className="characters">{candidats.Echelle}</span></p>
                          <p className="interligne alignement">Technique : <span className="characters">{candidats.Technique}</span></p>
                        </div>
                      )
                    })}</div>
                  </div>
                  <div className="resize">
                    <div className="container_final">
                      <div className="container_final_propositions_adjust">
                        <h1 className="title_client margin_title">Commentaires</h1>
                        <div className="container_final_propositions">
                          <p>Code LOINC Final : <span className="characters">{entry['Choix final de l\'expert']['code loinc']}</span></p>
                          <p>Code LOINC alternatif : <span className="characters">{entry['Choix final de l\'expert']['code loinc alternatif']}</span></p>
                        </div>
                      </div>
                      <div className="container_button">
                        <button className="button" onClick={() => this.prec()}>Précédent</button>
                        <button className="button" onClick={() => this.next()}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}

export default MappingDemo;