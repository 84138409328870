import React from 'react';
import '../App.css';
import chu from './bdd_pages.json'

class MappingPages extends React.Component {

  next(){
    window.scrollBy(0, 1110);
  }

  prec(){
    window.scrollBy(0, -1110);
  }

  render(){
    return(
      <div>
        {chu.map((entry, i) => {
          return(
            <div key={i}>
              <div className="background">
                <div className="container">
                  <div className="container_client">
                    <h1 className="title_client center">Entrée client</h1>
                    <h1 className="title_client">Entrée client</h1>
                    <p className="interligne sizing_entree">Code SA : <span className="characters">{entry['Entrée complète client']['Code SA']}</span></p>
                    <p className="interligne sizing_entree">Nom SA : <span className="characters">{entry['Entrée complète client'].['Nom SA']}</span></p>
                    <p className="interligne sizing_entree">Nom Analyse : <span className="characters">{entry['Entrée complète client']['Nom Analyse']}</span></p>
                    <p className="interligne sizing_entree">Type : <span className="characters">{entry['Entrée complète client']['Type']}</span></p>
                    <p className="interligne sizing_entree">Unité : <span className="characters">{entry['Entrée complète client'].['Unité']}</span></p>
                    <p className="interligne sizing_entree">Unité Conv : <span className="characters">{entry['Entrée complète client'].['Unité Conv']}</span></p>
                    <p className="interligne sizing_entree">Précision Conv : <span className="characters">{entry['Entrée complète client']['Précision Conv']}</span></p>
                    <p className="interligne sizing_entree">Unité SI : <span className="characters">{entry['Entrée complète client']['Unité SI']}</span></p>
                    <p className="interligne sizing_entree">Matrice : <span className="characters">{entry['Entrée complète client']['Matrice']}</span></p>
                    <div className="container_compteur">
                      <p>{i+1}/1543</p>
                    </div>
                  </div>
                  <div>
                    <h1 className="title_propositions margin_title">Propositions</h1>
                    <div className="container_propositions">{entry['Propositions LOINC'].map((candidats, index) => {
                      return(
                        <div key={index} className="container_propositions_cards">
                          <p className="characters">{index}</p>
                          <p className="interligne center"><span className="characters">{candidats.Composant}</span></p>
                          <p className="interligne center">{candidats['Code LOINC']}</p>
                          <hr></hr>
                          <p className="interligne alignement">Grandeur : <span className="characters">{candidats.Grandeur}</span></p>
                          <p className="interligne alignement">Milieu : <span className="characters">{candidats.Milieu}</span></p>
                          <p className="interligne alignement">Échelle : <span className="characters">{candidats.Echelle}</span></p>
                          <p className="interligne alignement">Technique : <span className="characters">{candidats.Technique}</span></p>
                        </div>
                      )
                    })}</div>
                  </div>
                  <div className="resize">
                    <div className="container_final">
                      <div className="container_final_propositions_adjust">
                        <h1 className="title_client margin_title">Commentaires</h1>
                        <div className="container_final_propositions">
                          <p>Proposition choisie par l'expert : <span className="characters">{entry["Proposition choisie par l'expert"]}</span></p>
                          <p>Code LOINC final : <span className="characters">{entry["Choix final de l'expert"]}</span></p>
                          <p>Code LOINC alternatif : <span className="characters">{entry["Choix final de l'expert"]['code loinc alternatif']}</span></p>
                        </div>
                      </div>
                      <div className="container_button">
                        <button className="button" onClick={() => this.prec()}>Précédent</button>
                        <button className="button" onClick={() => this.next()}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}

export default MappingPages;
