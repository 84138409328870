import React from 'react';
import '../App.css';
import MappingChu from './mapping_chu'

class chu_montpellier extends React.Component{
    render(){
        return(
            <div className="App">
                <MappingChu />
            </div>
        );
    }
}

export default chu_montpellier;